import React, { useEffect, useState } from "react"
import {
  Box,
  Heading,
  Button,
  Label,
  Flex,
  Text,
  Container,
  Input,
} from "theme-ui"
import axios from "axios"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import { Helmet } from "react-helmet"
import GlobalLayout from "../components/globalLayout"

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [params, setParams] = useState({
    id: "",
    token: "",
    password: "",
    confirm_password: "",
  })
  const [errorMessage, setErrorMessage] = useState("")

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    if (query.get("id") && query.get("token"))
      setParams({
        id: query.get("id"),
        token: query.get("token"),
      })
    else navigate("/")
  }, [])

  const onUpdateField = e => {
    const nextFormState = {
      ...params,
      [e.target.name]: e.target.value,
    }
    setParams(nextFormState)
  }

  const sendResetPassword = async event => {
    setLoading(true)

    console.log(params, params.password === params.confirm_password)

    if (params.password === params.confirm_password) {
      setErrorMessage("")
      axios
        .post("/.netlify/functions/resetPassword", params)
        .then(function (response) {
          console.log("response")
          setSuccess(true)
          setParams({
            password: "",
            confirm_password: "",
          })
          setLoading(false)
        })
        .catch(function (error) {
          console.log("error", error)
          setSuccess(false)
          setLoading(false)
        })
    } else {
      setErrorMessage("Le password non combaciano")
      setLoading(false)
    }
  }

  return (
    <GlobalLayout>
      <Helmet>
        <title>Imposta nuova password | DKB</title>
      </Helmet>
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={{ maxWidth: "413px", width: ["413px"] }}>
          <Box as="form" onSubmit={handleSubmit(sendResetPassword)}>
            <Label>Password</Label>
            <Input
              id="password"
              type="password"
              name="password"
              autocomplete="off"
              onChange={e => onUpdateField(e)}
              required
              minlength="8"
              value={params.password}
              sx={{ mb: [2] }}
            />
            <Text as="p" sx={{ fontSize: [0], color: "#999", mb: [3] }}>
              Lunghezza minima 8 caratteri
            </Text>
            <Label>Conferma password</Label>
            <Input
              id="confirm_password"
              type="password"
              name="confirm_password"
              autocomplete="off"
              required
              minlength="8"
              onChange={e => onUpdateField(e)}
              value={params.confirm_password}
              sx={{ mb: [2] }}
            />
            <Text as="p" sx={{ fontSize: [0], color: "#999", mb: [3] }}>
              Deve essere uguale alla password
            </Text>
            <Input
              sx={{
                mt: 0,
                backgroundColor: "primary",
                color: "white",
                paddingY: 3,
                transition: "background-color 300ms ease-in-out",
                "&:hover": {
                  backgroundColor: "dark",
                },
              }}
              type="submit"
              disabled={loading === true}
              value={loading === true ? "Invio..." : "Imposta nuova password"}
            />
            {errorMessage && (
              <Flex
                sx={{
                  maxWidth: "600px",
                  flexDirection: "column",
                  color: "red",
                  fontSize: [1],
                  mb: [3],
                }}
              >
                {errorMessage}
              </Flex>
            )}
          </Box>
          {success === true && (
            <Box>
              <Flex sx={{ maxWidth: "600px", flexDirection: "column" }}>
                <Text sx={{ fontSize: [2], mb: [3] }}>
                  <strong>Nuova password impostata!</strong>
                </Text>
              </Flex>
              <Box
                sx={{
                  textAlign: "left",
                  a: {
                    color: "#999999",
                    fontSize: [1],
                  },
                }}
              >
                <Link to="/">Vai al login</Link>
              </Box>
            </Box>
          )}
          {success === false && (
            <Flex
              sx={{
                maxWidth: "600px",
                flexDirection: "column",
                color: "red",
                fontSize: [1],
                mb: [3],
              }}
            >
              <Text>Non è stato possibile impostare la nuova password</Text>
            </Flex>
          )}
        </Box>
      </Container>
    </GlobalLayout>
  )
}

export default ResetPassword
